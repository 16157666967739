/** 
 * import : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
// conf, action
import itemActions from '@redux/item/actions'
// components
import Label from '@components/Typo/Label'
// containers
import ItemContainer from '@containers/ItemContainer'
// helper
// style, img, elm
const iconImg = require("@assets/images/title-icon-items.png")
// const
const { initGetItems, getItems } = itemActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.13 : usayama)
*/
export default function ItemListContainer(props) {

  /**
   * const
   *
   * @version 0.1 (2021.06.12 : usayama)
   */
  const dispatch = useDispatch()
  const { items } = useSelector(state => state.Item)

  /** 
   * useState : 
   * 
   * @version 0.1 (2021.06.14 : usayama)
   */
  const [ initPage, setInitPage ] = React.useState(false)

  /** 
   * componentDidMount : https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.12 : usayama)
  */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initGetItems())
      dispatch(getItems())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13 : usayama)
   * @version 0.2 (2021.06.14 : usayama) // 実装
   * @version 0.3 (2021.06.22 : usayama) // デザイン調整
   * @version 0.4 (2021.06.27 : usayama) // デザイン調整
   * @version 0.5 (2021.07.09 : usayama) // icon コメントアウト
   */
  return (
  <div className="shop-items wrap" id="items">
    <div className="title title-h-line">
      <h3><Label label="title.popular_items" /></h3>
      <div className="h-line">
        <span></span>
        {/*<img src={iconImg.default} alt="" />*/}
        <span></span>
      </div>
    </div>
    <div className="items">
      <div className="row">
      { items && items.map((item, i) => <ItemContainer item={item} key={i} /> )}
      </div>
    </div>
  </div>
  )
}
