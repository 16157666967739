/** 
 * import : 
 * 
 * @version 0.1 (2021.05.17 : usayama)
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.17 : usayama)
*/
const Pager = props => {

  /** 
   * const : 
   * 
     * @version 0.1 (2021.05.17 : usayama)
   */
  const { className } = props
  const classes = classNames(
    className,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama)
   */
  return (
    <div className={classes}>
    {
      /*
      <nav className="pager wrap">
        <ul className="pagination justify-content-center">
          <li className="page-item"><a className="page-link" href="#a">前へ</a></li>
          <li className="page-item"><a className="page-link" href="#b">1</a></li>
          <li className="page-item"><a className="page-link" href="#c">2</a></li>
          <li className="page-item"><a className="page-link" href="#d">3</a></li>
          <li className="page-item"><a className="page-link" href="#e">次へ</a></li>
        </ul>
      </nav>
      */
    }
    </div>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.05.17 : usayama)
*/
Pager.propTypes = {
  className: PropTypes.string,
  /*
  color: PropTypes.string,
  icon: PropTypes.node,
  heading: PropTypes.string,
  text: PropTypes.node,
  closeBt: PropTypes.bool,
  */
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.05.17 : usayama)
*/
Pager.defaultProps = {
  className: "",
  /*
  color: "primary",
  icon: null,
  heading: null,
  text: null,
  closeBt: false,
  */
}

/** 
 * export
 * 
 * @version 0.1 (2021.05.17 : usayama)
*/
export default Pager
