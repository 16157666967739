/** 
 * import : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
import React from 'react'
// conf, action
// components
// containers
import Pager from "@components/UIKit/Pager"
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.05.17 : usayama_dx)
*/
export default function PagerContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   */
  const { classes, children, ...attrs } = props

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.17 : usayama_dx)
   */
  return (
      <div className={classes} {...attrs}>
        <Pager />
      </div>
  )
}
