/**
 * import
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 * @version 0.2 (2021.06.28 : usayama) // Seo 追加
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import MypointContainer from '@containers/MypointContainer'
import NavlinkContainer from '@containers/NavlinkContainer'
import ItemListContainer from '@containers/ItemListContainer'
import PagerContainer from '@containers/PagerContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 */
const Items = () => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

  /** 
   * return : 
   * 
   * @version 0.1 (2021.05.13, 05.17 : usayama)
   * @version 0.2 (2021.06.28 : usayama) // Seo 追加
   * @version 0.3 (2021.09.30 : usayama) // NavlinkContainer コメントアウト
   */
	return <LayoutContainer>
    <Seo title={t('title.items')} />
		<MypointContainer />
		{/*<NavlinkContainer />*/}
		<ItemListContainer />
		<PagerContainer />
	</LayoutContainer>
}

/**
 * export
 *
 * @version 0.1 (2021.05.13, 05.17 : usayama)
 */
export default Items